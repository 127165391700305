import * as React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <p>404 page</p>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
